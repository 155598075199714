.sidebar {
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #f1f1f1;
    position: relative;
    height: auto;
    display: none;
    flex-direction: column;
  }
  .sidebarlinks {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .sidebarlinks a {
    text-align: center;
    width: 100%;
  }
  
  .sidebar a {
    display: block;
    color: black;
    padding: 16px;
    text-decoration: none;
    float: left;
  }
  
  .sidebar a:hover {
    background-color: #04AA6D;
    color: white;
  }

  .searchbox {
    display: none;
  }

  @media only screen and (max-width: 800px) {
    .searchbox {
        display: flex;
      }
  }