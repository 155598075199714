* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navbar {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 93px;
    flex-shrink: 0;
    flex-wrap: nowrap;
}

.brand {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 25px;
}

.links {
    margin-top: 40px;
    width: 40%;

}

#login-href{
    text-decoration: underline;
}

#links {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#yukarımargin {
    margin-bottom: 16px;
}

#sehirbahceleri {
    margin-top: 10px;
}

#link {
    text-decoration: none;
    color: black;
}

#link:hover {
    color: green;
}

#logo {
    width: 45px;
}

.icons {
    margin-right: 25px;
    width: 30%;

}

#icons {
    margin-top: 40px;
    list-style-type: none;
    display: flex;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    gap: 10px;
}

.icon {
    width: 24px;
    height: 24px;
}

#menu {
    display: none;
}

#searchpart {
    display: flex;
    gap: 10px;
    align-items: center;
}

.navbar-btn {
    transform: scale(0.8);
}

#sidebar {
    display: none;
}

.shopveuser {
    display: flex;
    margin-right: 50px;
    gap: 50px;
}

@media only screen and (max-width: 1500px) {
    .icons {
        width: 370px;
    }

    .links {
        width: 35%;
    }
}

@media only screen and (max-width: 1100px) {
    .links {
        display: none;
    }

    #menu {
        display: block;
    }

    #sidebar {
        display: block;
    }

    .icons {
        width: 60%;
    }
}

@media only screen and (max-width: 800px) {
    #searchpart {
        display: none;
    }



    .icons {
        width: 200px;
    }

    .navbar-btn {
        transform: scale(1.0);

    }
}