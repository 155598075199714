#ornekimage2 {
    display: none;
}

.container {
    padding: 40px;
    display: flex;
    gap: 100px;
}

#ornekimage {
    transition: all .15s;
    transform: scale(1);
    display: block;
    max-width: 40vw;
    max-height: 70vh;
    border: 6px solid var(--def_green);
    border-radius: 15px;
}
#ornekimage:hover{
    transition: all .15s;
    transform: scale(1.02);
    border-color: var(--active);
}

.details {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
}

.buttons {
    display: flex;
    gap: 30px;
}


#sepetepostala {
    width: 100%;
}

#seed-detail {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media only screen and (max-width: 1000px) {
    #ornekimage {
        width: 300px;
        height: 500px;
        margin-top: 50px;
    }
    .container {
        padding: 1cm;
    }
    .details {
        width: 500px;
    }
}

@media only screen and (max-width: 770px) {
    .container {
        margin-top: 20px;
    }
    #ornekimage {
        display: none;
    }
    #ornekimage2 {
        display: block;
        width: 200px;
        height: 250px;
    }
    .ayir {
        display: flex;
        justify-content: space-between;
    }
}