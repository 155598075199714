.OrderHistoryDetailContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.OrderHistoryDetailContainer h3 {
    text-align: center;
    margin-bottom: -20px;
    font-weight: bold;
}

.OrderHistoryDetailContainer input[type="text"] {
    height: min-content;
}

.OrderHistoryDetailContainer textarea {
    height: min-content;
    width: 100%;
    border: 6px solid var(--def_green);
    border-radius: 5px;
    transition: border 0.5s;
    transition: box-shadow 0.5s;
    padding: 5px;
    margin-bottom: 10px;
    resize: none;
}

.OrderInfoContainer {

    display: flex;
    justify-content: space-between;
    padding: 2cm;
    gap: 50px;
}

.OrderInfoContainer input {
    border: 6px solid var(--def_green);
    border-radius: 5px;
    width: 100%;
    height: 90px;
    transition: border 0.5s;
    transition: box-shadow 0.5s;
    padding: 5px;
}

.order-personal{
    display: flex;
    flex-direction: column;
    gap: 25px;
    height: 100%;
    width: 100%;
}



@media only screen and (max-width: 830px) {
    .OrderInfoContainer {
      flex-direction: column;
    }
}
  
@media only screen and (max-width: 500px) {
    .OrderInfoContainer {
      padding: 20px;
    }
}