.login-cont{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.login_form{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.input-cont{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
#inpt{
    width: 400px;
    height: 55px;
    padding-left: 20px;
    border-radius: 15px;
    border: 4px solid green;
    font-size: 18px;
    outline: none;
}
#inpt:focus {
    border-radius: 15px;
    box-shadow: var(--active_blue) 0px 0px 20px;
    border: 4px solid var(--active_blue);
}
#labl{
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    width: 400px;
}
#login-icon {
    width: 200px;
    height: 200px;
}

.login-sub {
    width: 200px;
}

@media only screen and (max-width: 490px) {
    .login_form {
        width: 100%;
        padding: 1cm;
    }
    #inpt {
        width: 100%;
    }
    #labl {
        width: 100%;
    }
    .login-sub {
        width: 100%;
    }
    #login-icon {
        width: 150px;
        height: 150px;
    }
}