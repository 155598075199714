h1 {
    text-align: center;
    margin-top: 20px;
    color: rgb(5, 52, 0);
    font-size: 70px;
    font-weight: 600;
}

#help_text {
    text-align: center;
    width: 70%;
    margin-top: 30px;
    font-size: x-large;
}


.help_page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#mark {
    height: 15%;
    width: 15%;
    display: flex;
    justify-content: center;
    align-content: center;
}

#marklogo {
    height: 50%;
    width: 50%;
    margin-top: 20px;
    filter: drop-shadow(10px 10px 15px rgba(0, 0, 0, 0.7));
}

.vl {
    width: 100%;
    height: 6px;
    background: var(--def_green);
    margin-top: 20px;

}

.help_cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
}

#href_link {
    color: blue;
    transition: all .2s;
}

#href_link:hover {
    color: var(--def_green);
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
}