.contactHead {
  text-align: center;
  margin-top: 15px;
}

.contactForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 50px;
  max-width: 700px;
  width: 95%;
  min-height: 500px;
  height: 60vh;
  position: relative;
  padding: 20px;
}

.contactForm input {
  border: 6px solid var(--def_green);
  border-radius: 5px;
  width: 100%;
  height: 15%;
  transition: border 0.5s;
  transition: box-shadow 0.5s;
  padding: 5px;
}

.contactForm input:hover {
  border-radius: 5px;
  box-shadow: var(--active_blue) 0px 0px 20px;
  border: 8px solid var(--active_blue);
}

.contactFormName {
  margin-bottom: 10px;
}

.contactFormMail {
  margin-bottom: 10px;
}

.contactFormMessage {
  width: 100%;
  height: 50%;
  border: 6px solid var(--def_green);
  border-radius: 5px;
  transition: border 0.5s;
  transition: box-shadow 0.5s;
  padding: 5px;
  margin-bottom: 10px;
  resize: none;
}

.contactFormMessage:hover {
  border: 8px solid var(--active_blue);
  border-radius: 5px;
  border-radius: 5px;
  box-shadow: var(--active_blue) 0px 0px 20px;
}

.captcha-cl {
  margin-bottom: 10px;
}
