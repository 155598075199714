.orderHistoryContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.orderHistoryCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  gap: 30px;
  padding: 1cm;
}

.orderHistoryCard {
  background-color: green;
  padding: 20px;
  border-radius: 15px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
