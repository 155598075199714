.deneme2{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#footerrr{
    position: relative;
    width: 100vw;
    margin-top: 20px;
}
#lorem_bitkibakım{
    text-align: center;
}
#BitkiBakımı{
    text-align: center;
}

.videos {
    display: flex;
    flex-wrap: wrap;
    padding: 1cm;
    gap: 100px;
}

.videocard {
    background-color: #b2b2b2;
    width: 326px;
    height: 200px;
    border-radius: 30px;
    text-align: center;
    padding-top: 50px;
}