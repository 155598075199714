* {
  margin: 0;
  box-sizing: border-box;
}

.seedcardorder {
    margin-top: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-left: 1vw;
  width: 40vw;
  flex-wrap: nowrap;
  background-color: rgb(217, 217, 217);
  height: 8vh;
  min-height: 80px;
  border: 6px solid var(--def_green);
  border-radius: 20px;
  align-items: center;
  padding: 8px;
}

.orderseedinfo {
  display: flex;
  border-radius: 10px;
  margin-left: 5%;
  width: 80%;
  height: 90%;
  min-height: 64px;
  right: 10px;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #bbccc0;
  align-items: center;
  position: relative;
}

#ordername {
  color: #000;
  font-family: Quicksand, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.orderinfo {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 10px;
}

.orderseed-img-container {
  position: relative;
  display: flex;
  width: 15%;

  height: 90%;
  min-height: 64px;
  border-radius: 15px;
  background-color: white;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.orderseed-img {
  max-width: 100%;
  max-height: 100%;
}

.numero {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.ordercreditcont {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 10px;
  width: 17%;
}

.order_seed_card_buttons {
  display: none;
}

#orderseednumber {
  color: #000;
  font-family: Quicksand, Times, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

#ordercredit {
  color: #000;
  font-family: Quicksand;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  width: 80%;
}

.count-and-numero-and-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40%;
}

@media only screen and (max-width: 1580px) {
  .orderseed-img-container {
    display: none;
  }

  .orderseedinfo {
    width: 100%;
  }

  #orderseednumber {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 830px) {
    .orderseedinfo {
        display: flex;
        border-radius: 10px;
        margin-left: 5%;
        width: 80%;
        height: 90%;
        min-height: 64px;
        right: 10px;
        flex-shrink: 0;
        justify-content: space-between;
        background-color: #bbccc0;
        align-items: center;
        position: relative;
      }
      .orderseed-img-container {
        position: relative;
        display: flex;
        width: 15%;
      
        height: 90%;
        min-height: 64px;
        border-radius: 15px;
        background-color: white;
        align-items: center;
        justify-content: center;
        align-content: center;
      }
      #orderseednumber {
        margin-left: 0px;
      }
      .seedcardorder {
        width: 98%;
        overflow-x:hidden;
      }
}

@media only screen and (max-width: 650px) {
  .orderseed-img-container {
    display: none;
  }

  .orderseedinfo {
    width: 100%;
  }

  #orderseednumber {
    margin-left: 50px;
  }
}