.profile_cont{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.account-set {
    padding: 1cm;
}

.account-list-ul {
    list-style-type: none;
}

.input-box {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.account-content {
    display: flex;

}

.account-button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.account-right {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 50px;
    width: 80vw;
}

#account-list-component:hover{
    color: blue;

}

.left-buttons{
    width: 13%;
    justify-content: space-between;
    gap: 5px;
    display: flex;
}

.trash{
    justify-content: space-between;
    gap: 10px;
    display: flex;  
}