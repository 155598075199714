.basket-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.basket-seeds {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.seedandinfo {
    margin: 20px;
    margin-top: 40px;
    display: flex;
    gap: 70px;
}

#footer_basket {
    position: relative;
    width: 100vw;
    margin-top: 20px;
}

#lorem_basket {
    text-align: center;
}

#Basket {
    margin: 40px;
}

.cart-info {
    display: flex;
    flex-direction: column;
    width: 50%;
}

@media only screen and (max-width: 1024px) {

    .basket-container {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .seedandinfo {
        margin: 20px;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    #basket {
        font-size: medium !important;
    }

    .cart-info {
        width: 100%;
    }
}