#hakkımızda {
    text-align: center;
}
#lorem{
    text-align: center;
    width: 70%;
    margin-top: 20px;
}

.deneme{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#sehirlogo{
    height:25%;
    width: 25%;
    display: flex;
    justify-content: center;
    align-content: center;

}
#sehirbahcelogo{
    height: 30%;
    width: 30%;
}

.vl {
    width: 100%;
    height: 6px;
    background: var(--def_green);
    margin-top: 20px;
    
  }
  .hk{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }