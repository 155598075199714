#gizlilik {
    text-align: center;
}
#lorem{
    text-align: center;
    width: 70%;
    margin-top: 20px;
}
#footerr{
    position: relative;
    width: 100vw;
    margin-top: 20px;
}
.security{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#security_icon{
    height:40%;
    width: 40%;
    display: flex;
    justify-content: center;
    align-content: center;

}
#securityiconvector{
    height: 15%;
    width: 15%;
}

.vl {
    width: 100%;
    height: 6px;
    background: var(--def_green);
    margin-top: 20px;
    
  }
  .scrty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }