* {
    margin: 0;
    box-sizing: border-box;
}

.seedcard {
    width: 300px;
    height: 500px;
    background-color: rgb(217, 217, 217);
    border-radius: 20px;
    border: 6px solid var(--def_green);
    display: flex;
    flex-direction: column;
    padding: 25px;
    justify-content: center;
    gap: 25px;
    align-items: center;
    position: relative;
}

.seedcard:hover {
    border-color: rgb(45, 5, 5);
    transform: scale(1.02);
    transition: all 0.15s;
}

.seedinfo {
    display: flex;
    border-radius: 10px;
    width: 270px;
    height: 80px;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: #BBCCC0;
    align-items: center;
    position: absolute;
    bottom: 7px;
}

#name {
    color: #000;
    font-family: Quicksand;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    width: 80%;
}

.grow {
    margin-top: -10px;
    display: flex;
    gap: 5px;
}

#grow {
    color: #565656;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#credit {
    width: 20%;
    margin-right: 20px;
    margin-top: 18px;
    font-weight: bold;
}

.info {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 10px;
}

.seed-img-container {
    width: 275px;
    height: 390px;
    position: absolute;
    top: 5px;
    display: flex;
    border-radius: 15px;
    background-color: white;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.seed-img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 15px;
}

@media only screen and (max-width: 1500px) {
    .seedcard {
        width: 300px;
        height: 500px;
    }
}