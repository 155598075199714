* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}

:root {
  --def_green: #2b5f3a;
  --active_blue: #5ab5dc;
  --active: rgb(45, 5, 5);
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 50px;
  gap: 70px;
}

.textpart1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
}

.textpart2 {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-left: 50px;
}

.introduction {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
  gap: 50px;
}

#shopnow {
  width: 178px;
  height: 50px;
  border-radius: 38px;
  background-color: black;
  color: white;
  margin-left: 50px;

  --border-angle: 0turn;
  --main-bg: conic-gradient(from var(--border-angle),
      #213,
      #112 5%,
      rgb(0, 65, 46) 60%,
      #213 95%);

  border: solid 1px transparent;
  border-radius: 2em;
  --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, rgb(50, 111, 0), rgb(255, 0, 0) 99%, transparent);

  background:
    var(--main-bg) padding-box,
    var(--gradient-border) border-box,
    var(--main-bg) border-box;

  background-position: center center;
}

#shopnow:hover {
  --border-angle: 0turn;
  --main-bg: conic-gradient(from var(--border-angle),
      #213,
      #112 5%,
      rgb(0, 65, 46) 60%,
      #213 95%);

  border: solid 5px transparent;
  border-radius: 2em;
  --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, rgb(50, 111, 0), rgb(255, 0, 0) 99%, transparent);

  background:
    var(--main-bg) padding-box,
    var(--gradient-border) border-box,
    var(--main-bg) border-box;

  background-position: center center;

  animation: bg-spin 2s linear infinite;
}

.buttongroup {
  display: flex;
  gap: 20px;
  transition: all 1s;
}

.buttongroup:hover {
  gap: 75px;
  transition: all 1s;
}

#introduction-image {
  width: 40rem;
  height: 50%;
  border: 2px black solid;
  border-radius: 25px;
  margin-right: 5rem;
  margin-top: 3rem;
  box-shadow: darkgreen 2px 2px 20px;
  transition: box-shadow cubic-bezier(0.075, 0.82, 0.165, 1) .5s;
}

#introduction-image {
  display: grid;
  place-content: center;
  color: white;
  text-shadow: 0 1px 0 #000;

  --border-angle: 0turn;
  --main-bg: conic-gradient(from var(--border-angle),
      #213,
      #112 5%,
      rgb(0, 65, 46) 60%,
      #213 95%);

  border: solid 5px transparent;
  border-radius: 2em;
  --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, rgb(50, 111, 0), rgb(255, 0, 0) 99%, transparent);

  background:
    var(--main-bg) padding-box,
    var(--gradient-border) border-box,
    var(--main-bg) border-box;

  background-position: center center;

  animation: bg-spin 5s linear infinite;
}

@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

#introduction-image:hover {
  box-shadow: darkgreen 2px 2px 40px;
}

#moreimage {
  width: 30px;
  height: 30px;
  margin-top: -3px;
}

#more {
  color: #000;
  font-family: Quicksand;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

#h6 {
  color: #000;
  font-family: Quicksand;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

#title {
  color: #010101;
  font-family: "Quicksand";
  font-size: 5rem;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: -0.5px;
  width: 600px;
  height: 220px;
}

.steps {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#process-image {
  width: 500px;
  height: 300px;
}

.information {
  display: flex;
  justify-content: space-between;
  background-color: #ededed;
  width: 100%;
  margin-top: -100px;
  padding-left: 2cm;
  padding-right: 2cm;
}

@media only screen and (max-width: 1220px) {
  #title {
    font-size: 70px;
  }

  #h6 {
    font-size: 26px;
  }

  .textpart1 {
    height: 380px;
  }

  #introduction-image {
    width: 430px;
    height: 40%;
  }
}

@media only screen and (max-width: 820px) {
  #title {
    font-size: 60px;
    width: 90%;
  }

  .textpart1 {
    height: 300px;
    justify-content: space-between;
  }

  #display650 {
    display: block;
    color: #000;
    font-family: Quicksand;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 80%;
    margin-top: -20px;
  }

  .textpart2 {
    display: none;
  }

  #introduction-image {
    width: 330px;
    height: 40%;
  }
}

@media only screen and (max-width: 715px) {
  .content {
    margin-top: 0;
  }

  #title {
    font-size: 50px;
  }

  #shopnow {
    margin-left: 20px;
  }

  #introduction-image {
    width: 270px;
    height: 40%;
  }

  #display650 {
    margin-top: -60px;
  }
}

@media only screen and (max-width: 610px) {
  .introduction {
    flex-direction: column-reverse;
  }

  #title {
    font-size: 40px;
  }

  #shopnow {
    margin-left: 0;
  }

  .textpart1 {
    height: 250px;
  }

  #introduction-image {
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  #title {
    font-size: 40px;
  }

  #shopnow {
    margin-left: 0;
  }

  .textpart1 {
    height: 250px;
  }

  #display650 {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .content {
    margin-left: 15px;
    margin-top: 0;
  }

  .textpart1 {
    height: 220px;
    margin-left: 0;
  }
}