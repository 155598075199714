* {
    margin: 0;
    box-sizing: border-box;
}

.seedcardbasket {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-left: 1vw;
    min-width: 53vw;
    flex-wrap: nowrap;
    background-color: rgb(217, 217, 217);
    height: 15vh;
    min-height: 80px;
    border: 6px solid var(--def_green);
    border-radius: 20px;
    align-items: center;
    padding: 8px;
}

.basketseedinfo {
    display: flex;
    border-radius: 10px;
    margin-left: 5%;
    width: 80%;
    height: 90%;
    min-height: 64px;
    right: 10px;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: #BBCCC0;
    align-items: center;
    position: relative;
}

#basketname {
    color: #000;
    font-family: Quicksand, Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    width: 80%;
}

.basketinfo {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 10px;
    width: 30%;
}

.basketseed-img-container {
    position: relative;
    display: flex;
    width: 15%;
    min-width: 100px;
    height: 90%;
    min-height: 64px;
    border-radius: 15px;
    background-color: white;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.basketseed-img {
    max-width: 100%;
    max-height: 100%;
}

.numero {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.basketcreditcont {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 10px;
    width: 17%;
}

.basket_seed_card_buttons {
    display: flex;
    gap: 10px;
    width: 10vw;
    margin-right: 10px;
}

#basketseednumber {
    color: #000;
    font-family: Quicksand, Times, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}

#basketcredit {
    color: #000;
    font-family: Quicksand;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    width: 80%;
}

.count-and-numero-and-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 40%;
}

#add {
    width: 100%;
}

#delete {
    width: 100%;
}

.numeroandcount {
    display: flex;
    width: 50%;
}

@media only screen and (max-width: 750px) {
    .basketseedinfo {
        flex-direction: column;
    }
}