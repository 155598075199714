#error {
  font-size: 80vh;
}
.err {
  width: 100vw;
  height: 90vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1050px) {
  #error {
    font-size: 40vw;
  }
}
