* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.footer {
    background-color: #F3F3F3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 125px;
    margin-top: 50px;
    width: 100%;
}

.footer-brand {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 25px;
}

.footer-links {
    width: 30%;
}

#footer-links {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
}

.footer-icons {
    margin-right: 50px;
    width: 300px;
}

#footer-icons {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
}

#footer-icons li img {
    width: 24px;
    height: 24px;
}

#asagimargin {
    margin-top: 17px;
}

#hr {
    width: 90%;
    display: none;
}

@media only screen and (max-width: 1100px) {
    .footer-icons {
        width: 20%;
    }
}

@media only screen and (max-width: 1000px) {
    .footer-links {
        width: 300px;
    }
    .footer-icons {
        width: 200px;
    }
}

@media only screen and (max-width: 840px) {
    .footer-icons {
        width: 150px;
    }
}

@media only screen and (max-width: 790px) {
    #asagimargin {
        margin-top: 0px;
    }
    .footer {
        flex-direction: column;
        height: 400px;
        align-items: center;
    }

    .footer-links {
        height: 200px;
    }

    .footer-brand {
        margin-top: 30px;
    }

    #footer-links {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 50px;
    }

    .footer-icons{
        margin-right: 0px;
    }

    #hr {
        display: block;
    }
}