.orderForm {
  display: flex;
  justify-content: space-between;
  padding: 2cm;
  gap: 50px;
}

.orderContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.orderForm input {
  border: 6px solid var(--def_green);
  border-radius: 5px;
  width: 100%;
  height: 90px;
  transition: border 0.5s;
  transition: box-shadow 0.5s;
  padding: 5px;
}

.orderForm input:hover {
  border-radius: 5px;
  box-shadow: var(--active_blue) 0px 0px 20px;
  border: 8px solid var(--active_blue);
}

.orderFormMessage {
  width: 100%;
  height: 300px;
  border: 6px solid var(--def_green);
  border-radius: 5px;
  transition: border 0.5s;
  transition: box-shadow 0.5s;
  padding: 5px;
  margin-bottom: 10px;
  resize: none;
}

.order-inputs {
  display: flex;
  flex-direction: column;
  gap: 25px;
  height: 100%;
  width: 100%;
}

.order-inputs h3 {
  text-align: center;
  margin-bottom: -20px;
  font-weight: bold;
}

.order-inputs input {
  height: min-content;
}

.order-inputs textarea {
  height: min-content;
}

.orderInfo {
  display: flex;
  flex-direction: column;
  gap: 50px;
  height: 100%;
}

.order-submit-buttons {
  display: flex;
  gap: 40px;
}

.orderFinish {
  display: flex;
  background-color: #f3f3f3;
  height: 300px;
  padding: 1cm;
  font-weight: bold;
  justify-content: space-between;
  border-radius: 20px;
}

.orderkalan {
  display: flex;
  gap: 40px;
}

#siparis-onay {
  width: 100%;
}

#siparis-iptal {
  width: 100%;
}

.ordersum {
  display: flex;
  gap: 20px;
}

.order-other-finish {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

#recaptcha-second {
  display: none;
}

.order-seeds-container {
  max-height: 400px;
  overflow-y: auto;
  position: relative;
}

@media only screen and (max-width: 1580px) {
  #recaptcha-second {
    display: block;
  }
  #recaptcha-first {
    display: none;
  }
  .order-other-finish {
    gap: 0px;
  }
  .orderFinish {
    padding: 10px;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 830px) {
  .orderForm {
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  .orderForm {
    padding: 20px;
  }
}
