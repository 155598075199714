.status-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    flex-wrap: wrap; /* Added to allow wrapping on smaller screens */
}

.status-item {
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0 10px;
    border: 2px solid #ccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    transition: background-color 0.5s ease, border-color 0.5s ease, transform 0.5s ease;
    font-size: 14px;
    text-align: center;
    padding: 10px;
}

.status-item.completed {
    background-color: #4caf50;
    border-color: #4caf50;
    color: white;
    font-weight: bold;
    animation: expand 0.5s forwards;
}

.status-item.active {
    border-color: #4caf50;
}

.status-bar-item {
    width: 50px;
    height: 10px;
    background-color: #ccc;
    transition: background-color 0.5s ease;
}

.status-bar-item.completed {
    background-color: #4caf50;
}

@keyframes expand {
    from {
        transform: scale(0.8);
    }
    to {
        transform: scale(1);
    }
}

@keyframes fillCircle {
    0% {
        background-color: #f0f0f0;
        border-color: #ccc;
    }
    100% {
        background-color: #4caf50;
        border-color: #4caf50;
        color: white;
    }
}

.status-item.cancelled {
    background-color: #f44336;
    border-color: #f44336;
    color: white;
    font-weight: bold;
}

.cancelled-description {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.description-box {
    padding: 20px;
    background-color: #f44336;
    border: 2px solid #f44336;
    border-radius: 5px;
    text-align: center;
}

.description-box p {
    margin: 0;
    color: white;
}

.tracking-button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #2196F3;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-align: center;
}

.tracking-button:hover {
    background-color: #1976D2;
    transform: scale(1.1);
}

/* Center the button */
.tracking-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Make sure the container takes full width */
}

/* Media Query for smaller screens */
@media (max-width: 600px) {
    .status-item {
        width: 60px;
        height: 60px;
        font-size: 10px;
    }

    .status-bar-item {
        width: 30px;
        height: 5px;
    }

    button, .tracking-button {
        padding: 5px 10px;
        font-size: 12px;
    }

    .description-box {
        padding: 10px;
    }
}
